import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from 'src/app/modals/interfaces/user-data-model';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LogoutService } from 'src/app/service/logout.service';
import { common_urls } from 'src/environments/environment';
import {
  BasicModalComponent,
  ModalConfig,
  BasicModalService,
} from '@cws-ui/ngx-cws-app-layout';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent {
  @ViewChild('modalBody') modalBody: any;
  @ViewChild('surveyModalBody') surveyModalBody: any;
  dialogConfig = new MatDialogConfig();
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private inactivityService: InactivityService,
    private cwsRedirectService: CwsRedirectService,
    private logoutService: LogoutService,
    private dialog: MatDialog,
    private BasicModalService: BasicModalService
  ) { }



  openSurveyModal() {
    const data: ModalConfig = {
      header: 'survey-logout-modal.header',
      buttons: [
        {
          text: 'survey-logout-modal.surveyAndLogoutBtn.text',
          subText: 'survey-logout-modal.surveyAndLogoutBtn.subText',
          result: OutputEvent.SURVEY,
          classes: 'btn btn1',
        },
        {
          text: 'survey-logout-modal.logoutBtn.text',
          subText: 'survey-logout-modal.logoutBtn.subText',
          result: OutputEvent.LOGOUT,
          classes: 'btn btn2',
        },
        {
          text: 'survey-logout-modal.cancelBtn.text',
          subText: 'survey-logout-modal.cancelBtn.subText',
          result: OutputEvent.CANCEL,
          classes: 'btn btn3',
        },
      ],
      customBody: this.surveyModalBody,
      customConfig:{
        autoFocus: true,
        restoreFocus: false
      },
      translate: true,
    };
    this.BasicModalService.openModal(data).subscribe((result) => {
      console.log(`New Dialog result: ${result}`);
      document.body.style.overflow = 'auto';
      if (result === OutputEvent.SURVEY) {
        this.logoutService.OIDClogout(
          `${common_urls.cwsBaseUrl}/MLJ_CWS_Ang_Logout?survey=true`
        );
      } else if (result === OutputEvent.LOGOUT) {
        this.logoutService.OIDClogout(
          `${common_urls.cwsBaseUrl}/secur/logout.jsp`
        );
      }
    });
  }

  openLogoutModal() {
    this.dialog.closeAll();
    const data: ModalConfig = {
      header: '',
      buttons: [
        {
          text: 'logout-modal.logoutBtn',
          result: OutputEvent.LOGOUT,
          classes: 'btn btn2',
        },
        {
          text: 'logout-modal.cancelBtn',
          result: OutputEvent.CANCEL,
          classes: 'btn btn3',
        },
      ],
      customBody: this.modalBody,
      customConfig:{
        autoFocus: true,
        restoreFocus: false
      },
      translate: true
    };
    this.BasicModalService.openModal(data).subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      document.body.style.overflow = 'auto';
      if (result === OutputEvent.LOGOUT) {
        this.logoutService.OIDClogout(
          `${common_urls.cwsBaseUrl}/secur/logout.jsp`
        );
      }
    })
  }

  openModal() {
    this.inactivityService.resetTimer();
    this.oidcSecurityService.checkAuth().subscribe(({ userData }) => {
      const user_data: UserData = userData;
      console.log('user data in header', user_data);
      const customAttributes = user_data?.custom_attributes;
      if (customAttributes && customAttributes.enableSurvey === 'true') {
        this.openSurveyModal();
      } else {
        this.openLogoutModal();
        // this.openSurveyModal();
      }
      document.body.style.overflow = 'hidden';
    });
  }

  /* function to redirect user on cws */
  // redirectToCWS() {
  //   // this.inactivityService.stopTrackingInactivity();
  //   // // window.location.href = this.cwsBackUrl;
  //   // this.oidcSecurityService.logoffLocal();
  //   // console.log('local logoff called');

  //   // const url: string = sessionStorage.getItem('cwsBackUrl') || '';
  //   // window.open(decodeURIComponent(url), '_self');
  //   this.cwsRedirectService.redirectToCWSBack();
  // }

  /* Function to redirect user to procUrl (CWS procedure url page) */
  redirectToProcPage(): void {
    this.cwsRedirectService.redirectToCwsProcUrl();
  }
}
export enum OutputEvent {
  LOGOUT = 'logout',
  CANCEL = 'cancel',
  SURVEY = 'survey',
}
