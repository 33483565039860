export const environment = {
  production: false,
  authority: 'www.test.salesforce.com',
  redirectUrl: 'https://ps.selfservice.mypage.manulife.co.jp',
  authWellknownEndpointUrl: 'https://mlj--ps01.sandbox.my.site.com/cws',
  clientId: '3MVG99S6MzYiT5k_oHtVAoooNHuXwBXyZ2800rlWBiAa_t5QqgYMqrapms_jrgczdtzVRZjPASmWEcZ4z2BGQ',
  scope: 'openid api',
  responseType: 'code',
};

export const common_urls = {
  bffBaseUrl: 'https://ps.apps.manulife.co.jp/ext/ps/cws/bff/api/v1',
  cwsBaseUrl: 'https://mlj--ps01.sandbox.my.site.com/cws',
  surveyUrl: '', // not in use
  footerNotesUrl: 'https://mlj--ps01.sandbox.my.site.com/cws/resource/1717685800000',
  footerTermsOfUseUrl: 'https://mlj--ps01.sandbox.my.site.com/cws/resource/1724841169000',
  oodMenuItemsUrl: 'https://ps.apps.manulife.co.jp/ext/ps/cws/bff/api/v1',
  adobeAnalyticsUrl: 'https://assets.adobedtm.com/b84dbf01908c/ee406859b983/launch-3ba032760f58-development.min.js'
}

export const common_url_sur = {
  bffBaseSurUrl: 'https://ps.apps.manulife.co.jp/ext/cws-sur/bff/api/v1/',
  cwsLoginUrl: "https://mlj--ps01.sandbox.my.site.com/cws/?cws=1",
  publicKey: 'p6DEJID6MhQP7WvcXCzTBJpzBpEgoRz521Ga5yNbYAs='
}

export const stop_mailing_certificates_url = {
  bffBaseUrl: 'https://ps.apps.manulife.co.jp/ext/cws/bff/api/v1',
  cwsBaseUrl: 'https://mlj--ps01.sandbox.my.site.com/cws',
  errorPage: '/error_page'
}

export const common_urls_gasa = {
  bffBaseUrl:'https://ps.apps.manulife.co.jp/ext/ps/cws/bff/api/v1/gasa',
  cwsBaseUrl:'https://mlj--ps01.sandbox.my.site.com/cws',
}

export const ereport_urls = {
  bffBaseEreportUrl: 'https://ps.apps.manulife.co.jp/ext/fraereport-ps/cws/bff/api/v1'
}

export const common_urls_c360 = {
  bffBaseUrl:'https://ps.apps.manulife.co.jp/ext/cws-c360/bff/api/ps/v1',
}

export const stp_cws_urls = {
  
  baseUrl: 'https://ps.apps.manulife.co.jp/ext/cws-sur/bff/api-ps/v1',
  cwsLoginUrl:"https://mlj--ps01.sandbox.my.site.com/cws/?cws=1",
  publicKey: 'p6DEJID6MhQP7WvcXCzTBJpzBpEgoRz521Ga5yNbYAs=',
  bffBaseUrl: 'https://ps.apps.manulife.co.jp/ext/ps/cws/bff/stp/api/v1',
  oodMenuItemsUrl: 'https://ps.apps.manulife.co.jp/ext/ps/cws/bff/api/v1'
}

